import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConfirmDialog from '../Helpers/ConfirmDialog'

import './../App.css';
import { Info, PinDropSharp } from '@material-ui/icons';

const AddSite = (props) => {

    const [siteid, setSiteid] = React.useState('');
    const [sitename, setSitename] = React.useState('');
    const [email1, setEmail1] = React.useState('');
    const [pass, setPass] = React.useState('');
    const [info, setInfo] = React.useState('');
    const [login, setLogin] = React.useState('');
    const [question, setQuestion] = React.useState('');
    const [answer, setAnswer] = React.useState('');
    const [email2, setEmail2] = React.useState('');
    const [phone1, setPhone1] = React.useState('');
    const [phone2, setPhone2] = React.useState('');
    const [birthDate, setBirthDate] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [city, setCity] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [postCode, setPostCode] = React.useState('');
    const [suname, setSuname] = React.useState('');
    const [pName, setPName] = React.useState('');
    const [secondName, setSecondName] = React.useState('');
    const [nickname, setNickname] = React.useState('');

    const [confirmOpen, setConfirmOpen] = React.useState(false);

    const hanSetEmail1 = (event) => {
        setEmail1(event.target.value);
        // props.selectedItem.name = event.target.value;
    };
    const hanSetSitename = (event) => {
        setSitename(event.target.value);
    };
    const hanSetLogin = (event) => {
        setLogin(event.target.value);
    };
    const hanSetPass = (event) => {
        setPass(event.target.value);
    };
    const hanSetInfo = (event) => {
        setInfo(event.target.value);
    };
    const hanSetSuname = (event) => {
        setSuname(event.target.value);
    };
    const hanSetPName = (event) => {
        setPName(event.target.value);
    };
    const hanSetSecondName = (event) => {
        setSecondName(event.target.value);
    };
    const hanSetPhone1 = (event) => {
        setPhone1(event.target.value);
    };
    const hanSetPhone2 = (event) => {
        setPhone2(event.target.value);
    };
    const hanSetEmail2 = (event) => {
        setEmail2(event.target.value);
    };
    const hanSetBirthDate = (event) => {
        setBirthDate(event.target.value);
    };
    const hanSetQuestion = (event) => {
        setQuestion(event.target.value);
    };
    const hanSetAnswer = (event) => {
        setAnswer(event.target.value);
    };

    const hanSetCountry = (event) => {
        setCountry(event.target.value);
    };
    const hanSetCity = (event) => {
        setCity(event.target.value);
    };
    const hanSetAddress = (event) => {
        setAddress(event.target.value);
    };
    const hanSetPostCode = (event) => {
        setPostCode(event.target.value);
    };
    const hanSetNickname = (event) => {
        setNickname(event.target.value);
    };



    const handleClose = () => {
        setEmail1('');
        setSitename('');
        setSiteid('');
        setPass('');
        setLogin('');
        setInfo('');
        setSuname('');
        setPName('');
        setSecondName('');
        setPhone1('');
        setPhone2('');
        setEmail2('');
        setBirthDate('');
        setQuestion('');
        setAnswer('');
        setCountry('');
        setCity('');
        setAddress('');
        setPostCode('');
        setNickname('');
        props.ShowAdd(false);
    };

    const handleEntered = () => {

        if (props.kindEdit == 2) {

            // alert(props.selectedItem.name);
            setSiteid(props.selectedItem.id);
            setEmail1(props.selectedItem.email1);
            setSitename(props.selectedItem.sitename);
            setPass(props.selectedItem.pass)
            setInfo(props.selectedItem.info)
            setLogin(props.selectedItem.login)
            setQuestion(props.selectedItem.question)
            setAnswer(props.selectedItem.answer)
            setEmail2(props.selectedItem.email2)
            setPhone1(props.selectedItem.phone1)
            setPhone2(props.selectedItem.phone2)
            setBirthDate(props.selectedItem.birthDate)
            setCountry(props.selectedItem.country)
            setCity(props.selectedItem.city)
            setAddress(props.selectedItem.address)
            setPostCode(props.selectedItem.postCode)
            setSuname(props.selectedItem.suname)
            setPName(props.selectedItem.pName)
            setSecondName(props.selectedItem.secondName)
            setNickname(props.selectedItem.nickname);
        }
    };

    const handleCreateClick = () => {
        if (props.kindEdit == 2) {
            setConfirmOpen(true);
        } else {
            handleCreate();
        }
    }

    const handleCreate = () => {
        if (!sitename || sitename == '' || sitename.length == 0) {
            props.ShowSnack(true, "Укажите имя Сайта");
            return;
        }
        props.ShowLoader(true);
        var req = new Object();
        req.accountId = props.accid;
        req.email1 = email1;
        req.sitename = sitename;
        req.login = login;
        req.pass = pass;
        req.info = info;
        req.question = question;
        req.answer = answer;
        req.email2 = email2;
        req.phone1 = phone1;
        req.phone2 = phone2;
        req.birthDate = birthDate;
        req.country = country;
        req.city = city;
        req.address = address;
        req.postCode = postCode;
        req.suname = suname;
        req.pName = pName;
        req.secondName = secondName;
        req.nickname = nickname;

        // если изменение
        if (props.kindEdit == 2) {
            req.id = siteid;
        }

        fetch(props.backurl + "addsite",
            {
                method: 'POST',
                headers: props.headers,
                body: JSON.stringify(req)
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log(result);
                    // var email = new Object();
                    // email.id = result.id;
                    // email.accountId = req.accountId;
                    // email.name = req.email;
                    // email.password = req.email;
                    // props.AddEmailFunc(email);
                    if (props.kindEdit == 2) {
                        props.DeleteItem(result.obj.id);
                        props.AddItem(result.obj);
                        // props.LoadItems(0);
                    } else {
                        // если добавление
                        props.AddItem(result.obj);
                    }
                    props.ShowAdd(false);
                    // props.LoadItems(0);
                    handleClose();
                },
                (error) => {
                    props.ShowSnack(true, "Ошибка - " + error)
                }
            ).finally(() => {
                // this.setState({ networkActivity: false });
                props.ShowLoader(false);
            });

    };

    return (

        <div>
            <Dialog open={props.showAddSite} onEntered={handleEntered} onClose={handleClose} aria-labelledby="form-dialog-title">
                {/* <Dialog open={props.showAddSite} onClose={handleClose} aria-labelledby="form-dialog-title"> */}
                <DialogTitle id="form-dialog-title">Добавление сайта</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {/* To subscribe to this website, please enter your email address here. We will send updates */}
                    </DialogContentText>
                    <div className='div-flex'>
                        <TextField fullWidth
                            margin="dense"
                            id="name"
                            label="Сайт*" value={sitename}
                            type="email" onChange={hanSetSitename}
                        /> <TextField
                            margin="dense"
                            id="name"
                            label="Логин" value={login}
                            type="email" onChange={hanSetLogin}
                        />
                        <TextField
                            margin="dense"
                            id="name"
                            label="Пароль"
                            type="text" value={pass} onChange={hanSetPass}

                        /><TextField
                            margin="dense"
                            id="name"
                            label="Никнейм" value={nickname}
                            type="email" onChange={hanSetNickname}
                        /><TextField fullWidth

                            margin="dense"
                            id="name"
                            label="Комментарий"
                            type="text" value={info} onChange={hanSetInfo}
                        />
                        <TextField

                            margin="dense"
                            id="name"
                            label="Почта1"
                            type="text" value={email1} onChange={hanSetEmail1}
                        /><TextField

                            margin="dense"
                            id="name"
                            label="Телфон1"
                            type="text" value={phone1} onChange={hanSetPhone1}
                        /><TextField

                            margin="dense"
                            id="name"
                            label="Телфон2"
                            type="text" value={phone2} onChange={hanSetPhone2}
                        /><TextField

                            margin="dense"
                            id="name"
                            label="Фамилия"
                            type="text" value={suname} onChange={hanSetSuname}

                        /><TextField

                            margin="dense"
                            id="name"
                            label="Имя"
                            type="text" value={pName} onChange={hanSetPName}
                        /><TextField

                            margin="dense"
                            id="name"
                            label="Отчество"
                            type="text" value={secondName} onChange={hanSetSecondName}
                        />
                        <TextField

                            margin="dense"
                            id="name"
                            label="Почта2"
                            type="text" value={email2} onChange={hanSetEmail2}
                        />
                        <TextField

                            margin="dense"
                            id="name"
                            label="Рождения"
                            type="text" value={birthDate} onChange={hanSetBirthDate}
                        /><TextField

                            margin="dense"
                            id="name"
                            label="Вопрос"
                            type="text" value={question} onChange={hanSetQuestion}
                        /><TextField

                            margin="dense"
                            id="name"
                            label="Ответ"
                            type="text" value={answer} onChange={hanSetAnswer}
                        />
                        <TextField

                            margin="dense"
                            id="name"
                            label="Страна"
                            type="text" value={country} onChange={hanSetCountry}
                        />
                        <TextField

                            margin="dense"
                            id="name"
                            label="Город"
                            type="text" value={city} onChange={hanSetCity}
                        />
                        <TextField

                            margin="dense"
                            id="name"
                            label="Адрес"
                            type="text" value={address} onChange={hanSetAddress}
                        />
                        <TextField

                            margin="dense"
                            id="name"
                            label="Индекс"
                            type="text" value={postCode} onChange={hanSetPostCode}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleCreateClick} color="primary">
                        Создать
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmDialog
                title="ИЗМЕНЕНИЕ?"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={handleCreate}
            >
                Изменить все данные Сайта?
            </ConfirmDialog>
        </div>
    );
}

export default AddSite;