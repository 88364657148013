import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConfirmDialog from '../Helpers/ConfirmDialog'
import { DataGrid } from '@material-ui/data-grid';

import './../App.css';
import { Info, PinDropSharp } from '@material-ui/icons';

const AddNote = (props) => {
    const [selectionModel, setSelectionModel] = React.useState([]);

    const handleSelected = (selected) => {
        // var obj = new Object();
        // obj.id = selected;
        // props.setSelectedItem(obj);
    };

    const [noteid, setNoteid] = React.useState('');
    const [noteidsub, setNoteidsub] = React.useState('');
    const [name, setName] = React.useState('');
    const [namesub, setNamesub] = React.useState('');
    const [info, setInfo] = React.useState('');
    const [infosub, setInfosub] = React.useState('');

    const [subnotes, setSubnotes] = React.useState([]);

    const [iscreated, setIscreated] = React.useState(true);

    const hanSetName = (event) => {
        setName(event.target.value);
    };
    const hanSetNamesub = (event) => {
        setNamesub(event.target.value);
    };
    const hanSetInfo = (event) => {
        setInfo(event.target.value);
    };
    const hanSetInfosub = (event) => {
        setInfosub(event.target.value);
    };

    const [confirmOpen, setConfirmOpen] = React.useState(false);

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'kkey', headerName: 'Имя', width: 180 },
        { field: 'vvalue', headerName: 'Значение', width: 180 },
        { field: 'createdStr', headerName: 'Создана', width: 130 },
        { field: 'updatedStr', headerName: 'Изменена', width: 130 },
    ];

    const handleClose = () => {
        setNoteid('');
        setName('');
        setNamesub('');
        setInfo('');
        setInfosub('');
        setSubnotes([]);
        setIscreated(false);
        setSelectionModel([]);
        // props.selectedItem = null;
        props.ShowAdd(false);
    };

    const handleEntered = () => {
        // debugger;
        if (props.kindEdit == 2) {
            // alert(props.selectedItem.name);

            setNoteid(props.selectedItem.id);
            setName(props.selectedItem.name);
            setNamesub('');
            setInfo(props.selectedItem.info);
            setInfosub('');
            setIscreated(true);
            setSubnotes(props.selectedItem.subNotes);
        } else {
            setIscreated(false);
        }
    };

    const handleCreateClick = () => {
        if (props.kindEdit == 2) {
            setConfirmOpen(true);
        } else {
            handleCreate();
        }
    }

    const handleCreateClickSubnote = () => {

        handleCreateSubnote();

    }

    const handleCreateSubnote = () => {
        if ((!namesub || namesub == '' || namesub.length == 0)) {
            props.ShowSnack(true, "Укажите Имя ");
            return;
        }
        // debugger;
        props.ShowLoader(true);
        var req = new Object();
        //req.accountId = props.accid;
        req.kkey = namesub;
        req.vvalue = infosub;
        // alert(noteid);
        req.noteId = parseFloat(noteid);

        // // если изменение
        // if (props.kindEdit == 2) {
        //     req.id = noteidsub;
        // }

        fetch(props.backurl + "addnotesub",
            {
                method: 'POST',
                headers: props.headers,
                body: JSON.stringify(req)
            }
        )
            .then(res => res.json())
            .then(
                (result) => {

                    // if (props.kindEdit == 2) {
                    //     props.DeleteItem(result.obj.id);
                    //     props.AddItem(result.obj);
                    //     // props.LoadItems(0);
                    // } else {
                    //   setIscreated(true);
                    // если добавление
                    // props.AddItem(result.obj);
                    // debugger;
                    // if (props.selectedItem.subNotes == null) {
                    //     props.selectedItem.subNotes = [];
                    //     props.selectedItem.subNotes.push(result.obj);
                    // } else {
                    //     props.selectedItem.subNotes.push(result.obj);
                    // }
                    if (props.selectedItem.subNotes == null) {
                        props.selectedItem.subNotes = [];
                        props.selectedItem.subNotes.push(result.obj);
                    } else {
                        props.selectedItem.subNotes.push(result.obj);
                    }
                    // props.selectedItem.subNotes.push(result.obj);
                    setSubnotes([]);
                    setSubnotes(props.selectedItem.subNotes);
                    setNamesub('');
                    setInfosub('');
                    props.ShowSnack(true, 'Добавлено успешно');
                    // }
                    // props.ShowAdd(false);
                    // props.LoadItems(0);
                    // handleClose();
                },
                (error) => {
                    props.ShowSnack(true, "Ошибка - " + error)
                }
            ).finally(() => {
                // this.setState({ networkActivity: false });
                props.ShowLoader(false);
            });
    }

    const handleCreate = () => {
        // if (!name || name == '' || name.length == 0) {
        //     props.ShowSnack(true, "Укажите имя Заметки");
        //     return;
        // }
        props.ShowLoader(true);
        var req = new Object();
        req.accountId = props.accid;
        req.name = name;
        req.info = info;

        // если изменение
        if (props.kindEdit == 2) {
            req.id = noteid;
        }

        fetch(props.backurl + "addnote",
            {
                method: 'POST',
                headers: props.headers,
                body: JSON.stringify(req)
            }
        )
            .then(res => res.json())
            .then(
                (result) => {

                    if (props.kindEdit == 2) {
                        props.DeleteItem(result.obj.id);
                        props.AddItem(result.obj);
                        // props.LoadItems(0);
                    } else {
                        // setIscreated(true);
                        // если добавление
                        // if (props.selectedItem.subNotes == null) {
                        //     props.selectedItem.subNotes = [];
                        //     props.selectedItem.subNotes.push(result.obj);
                        // }
                        // props.selectedItem = result.obj;
                        // debugger;
                        props.AddItem(result.obj);
                        setIscreated(true);
                        setNoteid(result.obj.id);
                    }
                    // props.ShowAdd(false);
                    // props.LoadItems(0);
                    // handleClose();
                },
                (error) => {
                    props.ShowSnack(true, "Ошибка - " + error)
                }
            ).finally(() => {
                // this.setState({ networkActivity: false });
                props.ShowLoader(false);
            });
    };

    const RemoveSubnote = () => {
        // debugger;
        if (!selectionModel || selectionModel.length == 0) {
            props.ShowSnack(true, 'Выберите Суб Ноте для удаления');
            return;
        }
        props.ShowLoader(true);
        var req = new Object();

        req.itemId = parseInt(parseFloat(selectionModel[0]));
        req.menuKind = 44;
        // if (permanent == true) {
        req.permanent = true;
        // }
        fetch(props.backurl + "deleteitem",
            {
                method: 'POST',
                headers: props.headers,
                body: JSON.stringify(req)
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    // alert(result.id);
                    if (props.showdeleted == false) {
                        // props.deleteitem(result.id);
                        // props.ClearSelected();
                    }

                    // if (permanent == true) {
                    //     // props.deleteitem(result.id);

                    // } else {
                    //     props.ShowSnack(true, 'Скрыт успешно');
                    // }
                    // debugger;
                    //var subnote = props.selectedItem.subNotes.filter(function (x) { return x.id = parseFloat(selectionModel[0]) });
                    //var ind = props.selectedItem.subNotes.indexOf(subnote);
                    //  props.selectedItem.subNotes.splice(ind, 1);
                    // setSubnotes(props.selectedItem.subNotes);
                    for (var i = 0; i < props.selectedItem.subNotes.length; i++) {
                        if (props.selectedItem.subNotes[i].id == parseFloat(selectionModel[0])) {
                            var ind = props.selectedItem.subNotes.indexOf(props.selectedItem.subNotes[i]);
                            // alert(ind);
                            props.selectedItem.subNotes.splice(ind, 1);

                            setSubnotes([]);
                            setSubnotes(props.selectedItem.subNotes);
                        }
                        i++;
                        // ещё какие-то выражения
                    }

                    props.ShowSnack(true, 'Удалено успешно');
                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    props.ShowSnack(true, 'Ошибка ' + error);
                }

            ).finally(() => {
                // this.setState({ networkActivity: false });
                props.ShowLoader(false);
            });


    };

    return (

        <div>
            <Dialog fullWidth open={props.showAddNote} onEntered={handleEntered} onClose={handleClose} aria-labelledby="form-dialog-title">
                {/* <Dialog fullWidth open={props.showAddNote} onClose={handleClose} aria-labelledby="form-dialog-title"> */}
                <DialogTitle id="form-dialog-title">Заметки</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {/* To subscribe to this website, please enter your email address here. We will send updates */}
                    </DialogContentText>
                    <div className='div-flex'>
                        <TextField
                            margin="dense"
                            id="name" fullWidth
                            label="Имя*" value={name}
                            type="text" onChange={hanSetName}
                        />
                        <TextField
                            fullWidth
                            margin="dense"
                            id="info"
                            label="Комментарий"
                            type="text" value={info} onChange={hanSetInfo}
                        />
                        <div className='div-flex'>
                            <div style={{ 'maxWidth': 900, width: '100%' }}>

                            </div>
                        </div>


                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Закрыть
                    </Button>
                    <Button onClick={handleCreateClick} color="primary">
                        Создать
                    </Button>
                </DialogActions>


                {iscreated &&
                    <div style={{ 'maxWidth': 900, width: '100%' }}>
                        <DataGrid density pageSize={25} headerHeight={25} rowHeight={25}
                            rows={subnotes} columns={columns} checkboxSelection={false} autoHeight={true}
                            onSelectionModelChange={(newSelection) => {
                                //let x = newSelection.selectionModel[0];
                                //console.log(x)
                                setSelectionModel(newSelection.selectionModel);
                                handleSelected(newSelection.selectionModel)
                                // setSelectionModel(newSelection);
                                // handleSelected(newSelection)

                            }}
                            selectionModel={selectionModel}
                        />

                    </div>

                }
                {iscreated &&
                    <DialogActions>
                        <TextField
                            margin="dense"
                            id="namesub" fullWidth
                            label="Имя*" value={namesub}
                            type="text" onChange={hanSetNamesub}
                        />
                        <TextField
                            margin="dense"
                            id="infosub" fullWidth
                            label="Значение*" value={infosub}
                            type="text" onChange={hanSetInfosub}
                        />

                        <Button onClick={handleCreateClickSubnote} color="primary">
                            Добавить
                        </Button>
                        <Button onClick={RemoveSubnote} color="primary">
                            Удалить
                        </Button>
                    </DialogActions>}


            </Dialog>

            <ConfirmDialog
                title="ИЗМЕНЕНИЕ"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={handleCreate}
            >
                Изменить все данные Кредитной карты?
            </ConfirmDialog>

        </div>
    );
}

export default AddNote;