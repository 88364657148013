import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConfirmDialog from '../Helpers/ConfirmDialog'

import './../App.css';
import { Info, PinDropSharp } from '@material-ui/icons';

const AddCredit = (props) => {

    const [creditid, setCreditid] = React.useState('');
    const [creditname, setCreditname] = React.useState('');
    const [number, setNumber] = React.useState('');
    const [dateend, setDateend] = React.useState('');
    const [code, setCode] = React.useState('');
    const [pin, setPin] = React.useState('');
    const [pinMobile, setPinMobile] = React.useState('');
    const [holdername, setHoldername] = React.useState('');
    const [info, setInfo] = React.useState('');
    const [type, setType] = React.useState('');
    const [codeword, setCodeword] = React.useState('');

    const [confirmOpen, setConfirmOpen] = React.useState(false);

    const hanSetCreditname = (event) => {
        setCreditname(event.target.value);
    };
    const hanSetNumber = (event) => {
        setNumber(event.target.value);
    };
    const hanSetDateend = (event) => {
        setDateend(event.target.value);
    };
    const hanSetCode = (event) => {
        setCode(event.target.value);
    };
    const hanSetPin = (event) => {
        setPin(event.target.value);
    };
    const hanSetPinMobile = (event) => {
        setPinMobile(event.target.value);
    };
    const hanSetHoldername = (event) => {
        setHoldername(event.target.value);
    };
    const hanSetInfo = (event) => {
        setInfo(event.target.value);
    };
    const hanSetType = (event) => {
        setType(event.target.value);
    };
    const hanSetCodeword = (event) => {
        setCodeword(event.target.value);
    };




    const handleClose = () => {
        setCreditid('');
        setCreditname('');
        setNumber('');
        setDateend('');
        setCode('');
        setPin('');
        setHoldername('');
        setInfo('');
        setType('');
        setCodeword('');
        setPinMobile('');

        props.ShowAdd(false);
    };

    const handleEntered = () => {
        if (props.kindEdit == 2) {
            // alert(props.selectedItem.name);
            setCreditid(props.selectedItem.id);
            setCreditname(props.selectedItem.name);
            setNumber(props.selectedItem.number);
            setDateend(props.selectedItem.dateend)
            setCode(props.selectedItem.code)
            setPin(props.selectedItem.pin)
            setHoldername(props.selectedItem.holderName)
            setInfo(props.selectedItem.info)
            setCodeword(props.selectedItem.codeword)
            setType(props.selectedItem.type)
            setDateend(props.selectedItem.dateEnd)
            setPinMobile(props.selectedItem.pinMobile)
        }
    };

    const handleCreateClick = () => {
        if (props.kindEdit == 2) {
            setConfirmOpen(true);
        } else {
            handleCreate();
        }
    }


    const handleCreate = () => {
        if (!creditname || creditname == '' || creditname.length == 0) {
            props.ShowSnack(true, "Укажите имя Карты");
            return;
        }
        props.ShowLoader(true);
        var req = new Object();
        req.accountId = props.accid;
        req.name = creditname;
        req.number = number;
        req.dateEnd = dateend;
        req.code = code;
        req.pin = pin;
        req.holderName = holdername;
        req.info = info;
        req.type = type;
        req.codeword = codeword;
        req.pinMobile = pinMobile;

        // если изменение
        if (props.kindEdit == 2) {
            req.id = creditid;
        }

        fetch(props.backurl + "addcredit",
            {
                method: 'POST',
                headers: props.headers,
                body: JSON.stringify(req)
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log(result);
                    // var email = new Object();
                    // email.id = result.id;
                    // email.accountId = req.accountId;
                    // email.name = req.email;
                    // email.password = req.email;
                    // props.AddEmailFunc(email);
                    if (props.kindEdit == 2) {
                        props.DeleteItem(result.obj.id);
                        props.AddItem(result.obj);
                        // props.LoadItems(0);
                    } else {
                        // если добавление
                        props.AddItem(result.obj);
                    }
                    props.ShowAdd(false);
                    // props.LoadItems(0);
                    handleClose();
                },
                (error) => {
                    props.ShowSnack(true, "Ошибка - " + error)
                }
            ).finally(() => {
                // this.setState({ networkActivity: false });
                props.ShowLoader(false);
            });
    };

    return (

        <div>
            <Dialog open={props.showAddCredit} onEntered={handleEntered} onClose={handleClose} aria-labelledby="form-dialog-title">
                {/* <Dialog open={props.showAddCredit} onClose={handleClose} aria-labelledby="form-dialog-title"> */}
                <DialogTitle id="form-dialog-title">Кредитные карты</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {/* To subscribe to this website, please enter your email address here. We will send updates */}
                    </DialogContentText>
                    <div className='div-flex'>
                        <TextField
                            margin="dense"
                            id="name"
                            label="Имя*" value={creditname}
                            type="text" onChange={hanSetCreditname}
                        /> <TextField
                            margin="dense"
                            id="name"
                            label="Номер кредитной карты" fullWidth value={number}
                            type="text" onChange={hanSetNumber}
                        />
                        <TextField
                            margin="dense"
                            id="name"
                            label="Код"
                            type="text" value={code} onChange={hanSetCode}

                        /><TextField

                            margin="dense"
                            id="name"
                            label="Пин"
                            type="text" value={pin} onChange={hanSetPin}
                        />
                        <TextField

                            margin="dense"
                            id="name"
                            label="Пин мобильный"
                            type="text" value={pinMobile} onChange={hanSetPinMobile}
                        /><TextField

                            margin="dense"
                            id="name"
                            label="Дата"
                            type="text" value={dateend} onChange={hanSetDateend}

                        /><TextField

                            margin="dense"
                            id="name"
                            label="Владелец"
                            type="text" value={holdername} onChange={hanSetHoldername}
                        /><TextField

                            margin="dense"
                            id="name"
                            label="Комментарий"
                            type="text" value={info} onChange={hanSetInfo}
                        />
                        <TextField

                            margin="dense"
                            id="name"
                            label="Тип карты"
                            type="text" value={type} onChange={hanSetType}
                        /><TextField

                            margin="dense"
                            id="name"
                            label="Кодовое слово"
                            type="text" value={codeword} onChange={hanSetCodeword}
                        />

                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleCreateClick} color="primary">
                        Создать
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmDialog
                title="ИЗМЕНЕНИЕ"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={handleCreate}
            >
                Изменить все данные Кредитной карты?
            </ConfirmDialog>
        </div>
    );
}

export default AddCredit;