import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConfirmDialog from '../Helpers/ConfirmDialog';
import FileUpload from '../Helpers/FileUpload';

import './../App.css';
import { Info, PinDropSharp } from '@material-ui/icons';

const AddKey = (props) => {

    const [keyid, setKeyid] = React.useState('');
    const [keyname, setKeyname] = React.useState('');
    const [target, setTarget] = React.useState('');
    const [info, setInfo] = React.useState('');
    const [keynum, setKeynum] = React.useState('');
    const [from, setFrom] = React.useState('');
    const [to, setTo] = React.useState('');

    const [confirmOpen, setConfirmOpen] = React.useState(false);

    const hanSetKeyname = (event) => {
        setKeyname(event.target.value);
    };
    const hanSetTarget = (event) => {
        setTarget(event.target.value);
    };
    const hanSetInfo = (event) => {
        setInfo(event.target.value);
    };
    const hanSetKeynum = (event) => {
        setKeynum(event.target.value);
    };
    const hanSetfrom = (event) => {
        setFrom(event.target.value);
    };
    const hanSetto = (event) => {
        setTo(event.target.value);
    };

    const handleClose = () => {
        setKeyid('');
        setKeyname('');
        setInfo('');
        setKeynum('');
        setFrom('');
        setTo('');
        setTarget('');
        props.ShowAdd(false);
        // props.SetFileName('');
    };

    const handleEntered = () => {
        if (props.kindEdit == 2) {
            setKeyid(props.selectedItem.id);
            setKeyname(props.selectedItem.name);
            setTarget(props.selectedItem.target);
            setFrom(props.selectedItem.from);
            setTo(props.selectedItem.to);
            setInfo(props.selectedItem.info);
            setKeynum(props.selectedItem.keyNum);
        }
    };

    const handleCreateClick = () => {
        if (props.kindEdit == 2) {
            setConfirmOpen(true);
        } else {
            handleCreate();
        }
    }

    const handleCreate = () => {
        if (!keyname || keyname == '' || keyname.length == 0) {
            props.ShowSnack(true, "Укажите имя Ключа");
            return;
        }
        props.ShowLoader(true);
        var req = new FormData();
        req.accountId = props.accid;
        req.name = keyname;
        req.info = info;
        req.keyNum = keynum;
        req.from = from;
        req.to = to;
        req.target = target;

        // если изменение
        if (props.kindEdit == 2) {
            req.id = keyid;
        }

        fetch(props.backurl + "addkey",
            {
                method: 'POST',
                headers: props.headers,
                body: JSON.stringify(req)
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log(result);
                    // var email = new Object();
                    // email.id = result.id;
                    // email.accountId = req.accountId;
                    // email.name = req.email;
                    // email.password = req.email;
                    // props.AddEmailFunc(email);
                    if (props.kindEdit == 2) {
                        // props.LoadItems(0);
                        props.DeleteItem(result.obj.id);
                        props.AddItem(result.obj);
                    } else {
                        // если добавление
                        props.AddItem(result.obj);
                    }


                    props.ShowAdd(false);

                    handleClose();
                },
                (error) => {
                    props.ShowSnack(true, "Ошибка - " + error)
                }
            ).finally(() => {
                // this.setState({ networkActivity: false });
                props.ShowLoader(false);
            });

    };

    return (

        <div >
            <Dialog open={props.showAddKey} onEntered={handleEntered} onClose={handleClose} maxWidth='sm'>
                {/* <Dialog open={props.showAddDocument} onClose={handleClose} maxWidth='sm'> */}
                <DialogTitle >Ключи</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className='div-flex' >
                            <TextField
                                margin="dense"
                                id="name"
                                label="Имя*" value={keyname}
                                type="text" onChange={hanSetKeyname}
                            />  <TextField
                                margin="dense"
                                id="target"
                                label="Цель" value={target}
                                type="text" onChange={hanSetTarget}
                            />
                            <TextField
                                margin="dense"
                                id="info"
                                label="Комментарий" fullWidth value={info}
                                type="text" onChange={hanSetInfo}
                            />
                            <TextField
                                margin="dense"
                                id="keyNum"
                                label="Ключ" fullWidth value={keynum}
                                type="text" onChange={hanSetKeynum}
                            /><TextField
                                margin="dense"
                                id="from"
                                label="С" value={from}
                                type="text" onChange={hanSetfrom}
                            />
                            <TextField
                                margin="dense"
                                id="to"
                                label="По" value={to}
                                type="text" onChange={hanSetto}
                            />
                        </div>
                    </DialogContentText>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleCreateClick} color="primary">
                        Создать/Изменить
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmDialog
                title="ИЗМЕНЕНИЕ"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={handleCreate}
            >
                Изменить все данные Ключа?
            </ConfirmDialog>
        </div>
    );
}

export default AddKey;