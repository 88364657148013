import './../Styles/Body.css'
import Button from "@material-ui/core/Button";



const Body = (props) => {
    // const handleClickLoad = () => {
    //     props.loaditems(props.menuid)
    // };


    if (props.accid == 0)
        return <div style={{ textAlign: 'center' }}>Сайт посвящён информационным технологиям, открытие скоро</div>;



    return (
        <div >
            {/* <Button onClick={handleClickLoad} color="primary" >загрузить111</Button> */}
            {/* боду {props.accid} {props.menuid} */}

        </div>
    );
}

export default Body;