import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConfirmDialog from '../Helpers/ConfirmDialog';
import FileUpload from '../Helpers/FileUpload';

import './../App.css';
import { Info, PinDropSharp } from '@material-ui/icons';

const AddCert = (props) => {

    const [certid, setCertid] = React.useState('');
    const [certname, setCertname] = React.useState('');
    const [info, setInfo] = React.useState('');
    const [target, setTarget] = React.useState('');
    const [privateKey, setPrivatekey] = React.useState('');
    const [from, setFrom] = React.useState('');
    const [to, setTo] = React.useState('');
    const [certif, setCertif] = React.useState('');
    const [csr, setCsr] = React.useState('');
    const [caCert, setCacert] = React.useState('');


    const [confirmOpen, setConfirmOpen] = React.useState(false);

    const hanSetCertid = (event) => {
        setCertid(event.target.value);
    };
    const hanSetCertname = (event) => {
        setCertname(event.target.value);
    };
    const hanSetInfo = (event) => {
        setInfo(event.target.value);
    };
    const hanSetTarget = (event) => {
        setTarget(event.target.value);
    };
    const hanSetPrivatekey = (event) => {
        setPrivatekey(event.target.value);
    };
    const hanSetFrom = (event) => {
        setFrom(event.target.value);
    };
    const hanSetTo = (event) => {
        setTo(event.target.value);
    };
    const hanSetCertif = (event) => {
        setCertif(event.target.value);
    };
    const hanSetCsr = (event) => {
        setCsr(event.target.value);
    };
    const hanSetCacert = (event) => {
        setCacert(event.target.value);
    };

    const handleClose = () => {
        setCertid('');
        setCertname('');
        setInfo('');
        setTarget('');
        setPrivatekey('');
        setFrom('');
        setTo('');
        setCertif('');
        setCsr('');
        setCacert('');

        props.ShowAdd(false);
        // props.SetFileName('');
    };

    const handleEntered = () => {
        if (props.kindEdit == 2) {
            setCertid(props.selectedItem.id);
            setCertname(props.selectedItem.name);
            setInfo(props.selectedItem.info);
            setTarget(props.selectedItem.target);
            setPrivatekey(props.selectedItem.privateKey);
            setFrom(props.selectedItem.from);
            setTo(props.selectedItem.to);
            setCertif(props.selectedItem.certif);
            setCsr(props.selectedItem.csr);
            setCacert(props.selectedItem.caCert);
        }
    };

    const handleCreateClick = () => {
        if (props.kindEdit == 2) {
            setConfirmOpen(true);
        } else {
            handleCreate();
        }
    }


    const handleCreate = () => {
        if (!certname || certname == '' || certname.length == 0) {
            props.ShowSnack(true, "Укажите имя Сертификата");
            return;
        }
        props.ShowLoader(true);
        var req = new FormData();
        req.accountId = props.accid;
        req.name = certname;
        req.info = info;
        req.target = target;
        req.privateKey = privateKey;
        req.from = from;
        req.to = to;
        req.certif = certif;
        req.csr = csr;
        req.caCert = caCert;

        // если изменение
        if (props.kindEdit == 2) {
            req.id = certid;
        }

        fetch(props.backurl + "addcert",
            {
                method: 'POST',
                headers: props.headers,
                body: JSON.stringify(req)
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log(result);
                    // var email = new Object();
                    // email.id = result.id;
                    // email.accountId = req.accountId;
                    // email.name = req.email;
                    // email.password = req.email;
                    // props.AddEmailFunc(email);
                    if (props.kindEdit == 2) {
                        // props.LoadItems(0);
                        props.DeleteItem(result.obj.id);
                        props.AddItem(result.obj);
                    } else {
                        // если добавление
                        props.AddItem(result.obj);
                    }


                    props.ShowAdd(false);

                    handleClose();
                },
                (error) => {
                    props.ShowSnack(true, "Ошибка - " + error)
                }
            ).finally(() => {
                // this.setState({ networkActivity: false });
                props.ShowLoader(false);
            });

    };

    return (

        <div >
            <Dialog open={props.showAddCert} onEntered={handleEntered} onClose={handleClose} maxWidth='sm'>
                {/* <Dialog open={props.showAddDocument} onClose={handleClose} maxWidth='sm'> */}
                <DialogTitle >Сертификаты</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className='div-flex' >
                            <TextField fullWidth
                                margin="dense"
                                id="name"
                                label="Имя*" value={certname}
                                type="text" onChange={hanSetCertname}
                            />
                            <TextField fullWidth
                                margin="dense"
                                id="target"
                                label="Цель" value={target}
                                type="text" onChange={hanSetTarget}
                            />
                            <TextField
                                margin="dense"
                                id="from"
                                label="С" value={from}
                                type="text" onChange={hanSetFrom}
                            />
                            <TextField
                                margin="dense"
                                id="to"
                                label="По" value={to}
                                type="text" onChange={hanSetTo}
                            />
                            <TextField
                                margin="dense"
                                id="info"
                                label="Комментарий" fullWidth value={info}
                                type="text" onChange={hanSetInfo}
                            />
                            <TextField fullWidth
                                margin="dense"
                                id="privateKey"
                                label="Private key" value={privateKey}
                                type="text" onChange={hanSetPrivatekey}
                            />
                            <TextField
                                margin="dense"
                                id="certif"
                                label="Сертификат" fullWidth value={certif}
                                type="text" onChange={hanSetCertif}
                            />
                            <TextField
                                margin="dense"
                                id="csr"
                                label="Csr" fullWidth value={csr}
                                type="text" onChange={hanSetCsr}
                            />
                            <TextField
                                margin="dense"
                                id="caCert"
                                label="Са Серт" fullWidth value={caCert}
                                type="text" onChange={hanSetCacert}
                            />

                        </div>

                    </DialogContentText>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleCreateClick} color="primary">
                        Создать/Изменить
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmDialog
                title="ИЗМЕНЕНИЕ"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={handleCreate}
            >
                Изменить все данные Сертификата?
            </ConfirmDialog>
        </div>
    );
}

export default AddCert;