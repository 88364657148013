import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Emails from './Body/Emails';
import Sites from './Body/Sites';
import Credits from './Body/Credits';
import Notes from './Body/Notes';
import Certs from './Body/Certs';
import Keys from './Body/Keys';
import Documents from './Body/Documents'
import Button from "@material-ui/core/Button";
import ConfirmDialog from '../Helpers/ConfirmDialog'
import Checkbox from '@material-ui/core/Checkbox';
import { Container } from '@material-ui/core';

// function TabPanel(props) {
const TabPanel = (props) => {
    // alert(props);
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Container>
                    <Box p={3}>
                        {/* <Typography component={ }><div>{children}</div></Typography> */}
                        {/* <Typography >{children}</Typography> */}
                        {children}
                    </Box>
                </Container>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


const MenuTop = (props) => {
    // export default function FullWidthTabs() {

    const theme = useTheme();
    // для выбранной Таб
    const [value, setValue] = React.useState(0);
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [permanent, setPermanent] = React.useState(false);
    // для выбранного Итема
    // const [selItem, setSelItem] = React.useState(false);

    const handleChange = (event, newValue) => {
        props.setmenu(newValue);
        setValue(newValue);
        props.ClearSelected();

    };

    const handleChangeIndex = (index) => {

        setValue(index);
    };

    const handleClickLoad = () => {

        props.loaditems(props.menuid)

    };

    const handleClickAdd = () => {
        // var obj = new Object();
        // props.setSelectedItem(obj);
        // props.ClearSelected();
        props.ShowAdd(true, 1)

    };

    const handleClickUpdate = () => {
        // props.ShowEditEmail(true)
        props.LoadItem();
        props.ShowAdd(true, 2)
    };

    const handleClickDelete = () => {
        if (!props.selected || props.selected.id == null || props.selected.id == 0) {
            props.ShowSnack(true, "Выберите элемент для удаления")
            return;
        }

        setPermanent(true);
        // if (!window.confirm("Are you sure?")) return;
        setConfirmOpen(true);
        return;
    };


    const handleClickHide = () => {
        if (!props.selected || props.selected.id == null || props.selected.id == 0) {
            props.ShowSnack(true, "Выберите элемент для удаления")
            return;
        }
        setPermanent(false);
        // if (!window.confirm("Are you sure?")) return;
        setConfirmOpen(true);
        return;
    };


    const SetSelItem = (itemId) => {
        // setSelItem(itemId.id);
        // props.SetSelectedItem(itemId.id);
    };


    const handleClear = () => {
        props.SetFilter('');
    };

    const RemoveItem = () => {
        props.ShowLoader(true);
        var req = new Object();
        req.itemId = parseInt(props.selected.id);
        req.menuKind = props.menuid;
        if (permanent == true) {
            req.permanent = true;
        }
        fetch(props.backurl + "deleteitem",
            {
                method: 'POST',
                headers: props.headers,
                body: JSON.stringify(req)
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    // alert(result.id);
                    if (props.showdeleted == false) {
                        props.deleteitem(result.id);
                        props.ClearSelected();
                    }

                    if (permanent == true) {
                        props.deleteitem(result.id);
                        props.ShowSnack(true, 'Удалено успешно');
                    } else {
                        props.ShowSnack(true, 'Скрыт успешно');
                    }

                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    props.ShowSnack(true, 'Ошибка ' + error);
                }

            ).finally(() => {
                // this.setState({ networkActivity: false });
                props.ShowLoader(false);
            });


    }

    const hanSetFilter = (event) => {
        props.SetFilter(event.target.value);
    };
    const hanSetShowdeleted = (event) => {
        props.SetShowdeleted(event.target.value);
    };

    return (
        <div >
            <Paper >
                <Tabs

                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label="Почты" />
                    <Tab label="Сайты" />
                    <Tab label="Кредитки" />
                    <Tab label="Документы" />
                    <Tab label="Заметки" />
                    <Tab label="Серты" />
                    <Tab label="Ключи" />
                </Tabs>
            </Paper>
            <div className='div-center'>
                <Button onClick={handleClickLoad} color="primary" >загрузить</Button>
                <Button onClick={handleClickAdd} color="primary" >добавить</Button>
                <Button onClick={handleClickUpdate} color="primary" >Изменить</Button>
                <Button onClick={handleClickDelete} color="primary" >удалить</Button>
                <Button onClick={handleClickHide} color="primary" >скрыть</Button>
                <br></br>
                <FormControlLabel

                    control={<Checkbox onChange={hanSetShowdeleted} color="primary" />}
                    label="все"
                />
                {/* <Checkbox

                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                /> */}
                <TextField
                    style={{ 'marginTop': -5, 'marginBottom': -10 }}
                    margin="dense"
                    id="name"
                    label="Фильтр по имени"
                    type="text"
                    value={props.filter} onChange={hanSetFilter}
                /> <Button onClick={handleClear} color="primary" >X</Button>
            </div>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <div >
                        <Emails selected={props.selected} setSelectedItem={props.setSelectedItem} backurl={props.backurl} itemsMails={props.itemsMails} />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <div >
                        <Sites setSelectedItem={props.setSelectedItem} selected={props.selected} backurl={props.backurl} itemsSites={props.itemsSites} />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <div >
                        <Credits setSelectedItem={props.setSelectedItem} selected={props.selected} backurl={props.backurl} itemsCredits={props.itemsCredits} />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <div >
                        <Documents setSelectedItem={props.setSelectedItem} selected={props.selected} backurl={props.backurl} itemsDocuments={props.itemsDocuments} />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={4} dir={theme.direction}>
                    <div >
                        <Notes setSelectedItem={props.setSelectedItem} selected={props.selected} backurl={props.backurl} itemsNotes={props.itemsNotes} />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={5} dir={theme.direction}>
                    {/* <div className="div-center">Сертификаты</div> */}
                    <div >
                        <Certs setSelectedItem={props.setSelectedItem} selected={props.selected} backurl={props.backurl} itemsCerts={props.itemsCerts} />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={6} dir={theme.direction}>
                    {/* <div className="div-center">Ключи</div> */}
                    <div >
                        <Keys setSelectedItem={props.setSelectedItem} selected={props.selected} backurl={props.backurl} itemsKeys={props.itemsKeys} />
                    </div>
                </TabPanel>
            </SwipeableViews>

            <ConfirmDialog
                title="УДАЛЕНИЕ"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={RemoveItem}
            >
                Удалить выбранную запись?
            </ConfirmDialog>
        </div >
    );
}

export default MenuTop