
import Button from "@material-ui/core/Button";
import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';


const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Имя   ', width: 210 },
    { field: 'info', headerName: 'Комментарий', width: 210 },
    { field: 'createdStr', headerName: 'Создана', width: 130 },
    { field: 'updatedStr', headerName: 'Изменена', width: 130 },
];




const Notes = (props) => {
    const [selectionModel, setSelectionModel] = React.useState([]);

    const handleSelected = (selected) => {
        var obj = new Object();
        obj.id = selected;
        props.setSelectedItem(obj);
    };
    return (
        <div className='div-flex'>
            <div style={{ 'maxWidth': 800, width: '100%' }}>
                <DataGrid density pageSize={25} headerHeight={25} rowHeight={25}
                    rows={props.itemsNotes} columns={columns} checkboxSelection={false} autoHeight={true}
                    onSelectionModelChange={(newSelection) => {
                        // let x = newSelection.selectionModel[0];
                        // console.log(x)
                        setSelectionModel(newSelection.selectionModel);
                        handleSelected(newSelection.selectionModel)

                    }}
                    selectionModel={selectionModel}
                />

            </div>

        </div>
    );
}

export default Notes;