import './../Styles/Footer.css'

function Footer() {
    return (
        <div className="Footer">
            DiStorage 2021
        </div>
    );
}

export default Footer;