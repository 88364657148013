import './../Styles/Login.css'
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import React from 'react';
import ReactDOM from 'react-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';

const Login = (props) => {
    const [open, setOpen] = React.useState(false);
    const [maxWidth] = React.useState('xs');
    const [email, setEmail] = React.useState('');
    const [pass, setPass] = React.useState('');
    // const [email, setEmail] = React.useState('test');
    // const [pass, setPass] = React.useState('parol');
    const handleClickOpen = () => {
        setOpen(true);
    };



    // нажатие Ок в диалоговом окне
    const handleOk = () => {
        Login();
    };

    const Login = () => {
        if (!email || email == '' || email.length == 0) {
            props.ShowSnack(true, "Введите почту")
            return;
        }

        props.ShowLoader(true);
        var req = new Object();
        req.email = email;
        req.pass = pass;
        fetch(props.backurl + "login",
            {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=utf-8' },
                body: JSON.stringify(req),
                // referrerPolicy: 'origin-when-cross-origin'
            }
        )
            // .then(res => res.json())
            .then((res) => {
                // console.log(res)
                if (res.status == 401) {
                    // console.log(1111)
                    // props.ShowSnack(true, "Ошибка авторизации, возможно токен просрочился");
                    var result = new Object();
                    result.ok = false;
                    result.errmsg = "Ошибка авторизации, возможно токен просрочился";;
                    return result;
                }
                if (res.status == 404) {
                    var result = new Object();
                    result.ok = false;
                    result.errmsg = "Не найден метод";;
                    return result;
                }
                if (res.status == 400) {
                    // var result = new Object();
                    // result.ok = false;
                    // result.errmsg = "????";;
                    // return result;
                }
                return res.json()
            })
            .then(
                (result) => {
                    // if (result.result == "400") {
                    //     props.ShowSnack(true, 'Не успешно');
                    // } else {
                    //     props.ShowSnack(true, 'успешно');
                    // }

                    if (result.ok) {
                        props.login(result.obj);
                    } else {
                        props.ShowSnack(true, result.errmsg);
                    }


                    // if (result.ok) {
                    //     props.ShowSnack(true, 'успешно');
                    //     console.log(result.response)
                    // } else {
                    //     props.ShowSnack(true, 'Не правильный логин или пароль');
                    // }

                },
                // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
                // чтобы не перехватывать исключения из ошибок в самих компонентах.
                (error) => {
                    console.log(error)
                    props.ShowSnack(true, 'Ошибка, возможно сервер не доступен ');
                }

            ).finally(() => {
                // this.setState({ networkActivity: false });
                props.ShowLoader(false);
            });


    }


    // нажатие Отмена в диалоговом окне
    const handleClose = () => {
        setOpen(false);
    };
    // нажатие Выход 
    const handleLogOut = () => {
        setOpen(false);
        props.logout();

    };

    const handlesetEmail = (event) => {
        setEmail(event.target.value);
    };

    const handlesetPass = (event) => {
        setPass(event.target.value);
    };

    // если Вход выполнен
    if (props.accid != 0)
        return (
            <div className="Login" >
                <Button color="primary" onClick={handleLogOut}>
                    Выход
                </Button>
            </div>
        );

    // если Вход НЕ выполнен
    return (
        <div className="Login" >

            <Button color="primary" onClick={handleClickOpen}>
                Вход
            </Button>

            <Dialog
                disableEscapeKeyDown maxWidth={maxWidth} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Вход</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText>
                        To subscribe to this website, please enter your email address here. We will send updates
                        occasionally.
                    </DialogContentText> */}
                    <TextField
                        margin="dense"
                        id="name"
                        label="Почта"
                        type="email"
                        fullWidth onChange={handlesetEmail}
                        value={email}
                    />
                    <TextField

                        margin="dense"
                        id="name"
                        label="Пароль"
                        type="password"
                        fullWidth
                        onChange={handlesetPass}
                        value={pass}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleOk} color="primary">
                        Войти
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}



export default Login;