
import Button from "@material-ui/core/Button";
import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';


const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'name', headerName: 'Имя Ключа', width: 210 },
    { field: 'target', headerName: 'Цель', width: 210 },
    // { field: 'info', headerName: 'Комментарий', width: 180 },
    { field: 'from', headerName: 'С', width: 130 },
    { field: 'to', headerName: 'По', width: 130 },
    { field: 'createdStr', headerName: 'Создана', width: 130 },
    { field: 'updatedStr', headerName: 'Обновлена', width: 130 },
    // {
    //     field: 'age',
    //     headerName: 'Age',
    //     type: 'number',
    //     width: 90,
    // },
    // {
    //     field: 'fullName',
    //     headerName: 'Full name',
    //     description: 'This column has a value getter and is not sortable.',
    //     sortable: false,
    //     width: 160,
    //     valueGetter: (params) =>
    //         `${params.getValue('firstName') || ''} ${params.getValue('lastName') || ''}`,
    // },
];



const Keys = (props) => {
    const [selectionModel, setSelectionModel] = React.useState([]);

    const handleSelected = (selected) => {
        var obj = new Object();
        obj.id = selected;
        props.setSelectedItem(obj);
    };

    return (
        <div className='div-flex'>
            <div style={{ 'maxWidth': 1100, width: '100%' }}>
                <DataGrid density pageSize={15} headerHeight={25} rowHeight={25}
                    rows={props.itemsKeys} columns={columns} pageSize={8} checkboxSelection={false} autoHeight={true}
                    density
                    onSelectionModelChange={(newSelection) => {
                        //let x = newSelection.selectionModel[0];
                        //console.log(x)
                        setSelectionModel(newSelection.selectionModel);
                        handleSelected(newSelection.selectionModel)
                        // setSelectionModel(newSelection);
                        // handleSelected(newSelection)

                    }}
                    selectionModel={selectionModel}
                />
            </div>
        </div>
    );
}

export default Keys;