import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';

import Header from './Components/Header';
import Login from './Components/Login';
import MenuTopWrap from './Components/MenuTopWrap';

import Body from './Components/Body';
import Footer from './Components/Footer';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import AddEmail from './Components/AddEmail'
import AddSite from './Components/AddSite'
import AddCredit from './Components/AddCredit'
import AddNote from './Components/AddNote'
import AddCert from './Components/AddCert'
import AddKey from './Components/AddKey'
import AddDocument from './Components/AddDocument'
import Snack from './Helpers/Snack'
import Loader from './Helpers/Loader'
import { ThreeSixty } from '@material-ui/icons';

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      accid: 0,
      token: "",
      menuid: 0,
      itemsMails: [],
      itemsSites: [],
      itemsCredits: [],
      itemsNotes: [],
      itemsDocuments: [],
      itemsCerts: [],
      itemsKeys: [],
      // backurl: 'http://localhost:7777/',
      backurl: 'https://дистор.рф/',
      showAddMail: false,
      showAddSite: false,
      showAddCredit: false,
      showAddNote: false,
      showAddDocument: false,
      showAddCert: false,
      showAddKey: false,
      selected: new Object(),
      kindEdit: 1,
      snackbar: false,
      snackmsg: '',
      loading: false,
      headers: {},
      filter: '',
      showdeleted: false,
      file: new Object(),
    };
    this.Login = this.Login.bind(this);
    this.LogOut = this.LogOut.bind(this);
    this.SetMenu = this.SetMenu.bind(this);
    this.LoadItems = this.LoadItems.bind(this);
    this.LoadItem = this.LoadItem.bind(this);
    this.DeleteItem = this.DeleteItem.bind(this);
    this.ShowAdd = this.ShowAdd.bind(this);
    // this.AddEmailFunc = this.AddEmailFunc.bind(this);
    this.SetSelectedItem = this.SetSelectedItem.bind(this);
    this.ClearSelected = this.ClearSelected.bind(this);
    this.ShowSnack = this.ShowSnack.bind(this);
    this.ShowLoader = this.ShowLoader.bind(this);
    this.AddItem = this.AddItem.bind(this);
    this.SetFilter = this.SetFilter.bind(this);
    this.SetShowdeleted = this.SetShowdeleted.bind(this);
    this.SetFile = this.SetFile.bind(this);
    this.SaveFile = this.SaveFile.bind(this);
  }

  Login(result) {

    this.setState({
      accid: result.accid,
      token: result.token,
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
        'Authorization': `Bearer ${result.token}`
      },
      headersDoc: {
        'Authorization': `Bearer ${result.token}`
      },
    });
  }


  LogOut() {
    this.setState({
      accid: 0,
      token: "",
      menuid: 0,
      itemsMails: [],
      itemsSites: [],
      itemsCredits: [],
      itemsNotes: [],
      itemsDocuments: [],
      itemsCerts: [],
      itemsKeys: [],
      selected: new Object(),
      kindEdit: 1,
      headers: {},
      filter: '',
      showdeleted: false,
      file: new Object(),
    })
  }

  SetMenu(menuind) {
    this.setState({
      menuid: menuind,
    })
  }

  // SetHeaders(headers) {
  //   this.setState({
  //     headers: headers,
  //   })
  // }

  ShowAdd(vis, kindEd) {
    // если Изменение
    if (kindEd == 2) {
      if (!this.state.selected || !this.state.selected.id || this.state.selected.id == 0) {
        this.ShowSnack(true, "Не выбран элемент для изменения");
        return;
      }
    }

    if (this.state.menuid == 0) {
      this.setState({
        showAddMail: vis,
        kindEdit: kindEd
      })
    } else if (this.state.menuid == 1) {
      this.setState({
        showAddSite: vis,
        kindEdit: kindEd
      })
    } else if (this.state.menuid == 2) {
      this.setState({
        showAddCredit: vis,
        kindEdit: kindEd
      })
    } else if (this.state.menuid == 3) {
      this.setState({
        showAddDocument: vis,
        kindEdit: kindEd
      })
    } else if (this.state.menuid == 4) {
      this.setState({
        showAddNote: vis,
        kindEdit: kindEd
      })
    } else if (this.state.menuid == 5) {

      this.setState({
        showAddCert: vis,
        kindEdit: kindEd
      })
    } else if (this.state.menuid == 6) {

      this.setState({
        showAddKey: vis,
        kindEdit: kindEd
      })
    }


  }

  SetSelectedItem(item) {
    this.setState({
      selected: item
    })
  }

  SetFile(item) {
    this.setState({
      file: item
    })
  }

  SaveFile(documentid) {
    const formData = new FormData();
    formData.append("files", this.state.file);
    formData.append("documentid", documentid);

    // const res = axios.post(this.state.backurl + "AddDoc", formData);
    // console.log(res);
    // alert(1);

    fetch(this.state.backurl + "AddDoc",
      {
        method: 'POST',
        headers: this.state.headersDoc,
        // headers: {
        //   // 'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
        //   // 'Content-Type': 'multipart/form-data'
        // },
        body: formData
        // body: JSON.stringify(formData)
      }
    )
      .then(res => res.json())
      .then(
        (result) => {

        },
        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
        // чтобы не перехватывать исключения из ошибок в самих компонентах.
        (error) => {
        }
      )
      .finally(() => {
        // this.setState({ networkActivity: false });
        this.ShowLoader(false);
      });
  }

  AddItem(item) {
    if (this.state.menuid == 0) {
      let floors = [...this.state.itemsMails];
      floors.push(item);
      this.setState({
        itemsMails: floors
      })
    } else if (this.state.menuid == 1) {
      let floors = [...this.state.itemsSites];
      floors.push(item);
      this.setState({
        itemsSites: floors
      })
    } else if (this.state.menuid == 2) {
      let floors = [...this.state.itemsCredits];
      floors.push(item);
      this.setState({
        itemsCredits: floors
      })
    } else if (this.state.menuid == 3) {
      let floors = [...this.state.itemsDocuments];
      floors.push(item);
      this.setState({
        itemsDocuments: floors
      })
    } else if (this.state.menuid == 4) {
      let floors = [...this.state.itemsNotes];
      floors.push(item);
      this.setState({
        itemsNotes: floors
      })
    } else if (this.state.menuid == 5) {
      let floors = [...this.state.itemsCerts];
      floors.push(item);
      this.setState({
        itemsCerts: floors
      })
    } else if (this.state.menuid == 6) {
      let floors = [...this.state.itemsKeys];
      floors.push(item);
      this.setState({
        itemsKeys: floors
      })
    }
  }


  ClearSelected() {
    this.setState({
      selected: new Object()
    })
  }

  ShowSnack(snackVis, snackMsg) {
    this.setState({
      snackbar: snackVis,
      snackmsg: snackMsg
    })
  }

  ShowLoader(vis) {
    this.setState({
      loading: vis
    })
  }

  SetFilter(val) {
    this.setState({
      filter: val
    })
  }

  SetShowdeleted(val) {
    if (!this.state.showdeleted || this.state.showdeleted == false) {
      this.setState({
        showdeleted: true
      })
    } else {
      this.setState({
        showdeleted: false
      })
    }

  }

  LoadItem = () => {
    // this.ShowLoader(true);
    var req = new Object();
    req.itemId = parseInt(this.state.selected.id);
    req.menuKind = this.state.menuid;

    fetch(this.state.backurl + "getitem",
      {
        method: 'POST',
        headers: this.state.headers,
        body: JSON.stringify(req)
      }
    )
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            selected: result
          })
        },
        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
        // чтобы не перехватывать исключения из ошибок в самих компонентах.
        (error) => {
        }
      )
      .finally(() => {
        // this.setState({ networkActivity: false });
        this.ShowLoader(false);
      });
  }

  DeleteItem(itemid) {
    // console.log(itemid);
    // var ind = this.state.itemsMails.indexOf(itemid);
    // console.log(ind);
    // this.state.itemsMails.splice(0, 1);
    if (this.state.menuid == 0) {
      this.setState({
        itemsMails: this.state.itemsMails.filter(item => item.id !== itemid)
      })
    }
    if (this.state.menuid == 1) {
      this.setState({
        itemsSites: this.state.itemsSites.filter(item => item.id !== itemid)
      })
    }
    if (this.state.menuid == 2) {
      this.setState({
        itemsCredits: this.state.itemsCredits.filter(item => item.id !== itemid)
      })
    }
    if (this.state.menuid == 3) {
      this.setState({
        itemsDocuments: this.state.itemsDocuments.filter(item => item.id !== itemid)
      })
    }
    if (this.state.menuid == 4) {
      this.setState({
        itemsNotes: this.state.itemsNotes.filter(item => item.id !== itemid)
      })
    }
    if (this.state.menuid == 5) {
      this.setState({
        itemsCerts: this.state.itemsCerts.filter(item => item.id !== itemid)
      })
    }
    if (this.state.menuid == 6) {
      this.setState({
        itemsKeys: this.state.itemsKeys.filter(item => item.id !== itemid)
      })
    }
  }



  LoadItems() {
    this.ShowLoader(true);
    var req = new Object();
    req.accId = this.state.accid;
    req.menuKind = this.state.menuid;
    req.filter = this.state.filter;
    req.showHidden = this.state.showdeleted;

    fetch(this.state.backurl + "getitems",
      {
        method: 'POST',
        // headers: {
        //   'Content-Type': 'application/json;charset=utf-8',
        //   'Authorization': `Bearer ${this.state.token}`
        // },
        headers: this.state.headers,
        body: JSON.stringify(req)
        // body: 1
      }
    )
      .then(res => res.json())
      .then(
        (result) => {

          // если почты
          if (req.menuKind == 0) {
            this.setState({
              itemsMails: result
            });
          }
          // если сайты
          if (req.menuKind == 1) {
            this.setState({
              itemsSites: result
            });
          }
          // если кредитки
          if (req.menuKind == 2) {
            this.setState({
              itemsCredits: result
            });
          }
          // если документы
          if (req.menuKind == 3) {
            this.setState({
              itemsDocuments: result
            });
          }
          // если заметки
          if (req.menuKind == 4) {
            this.setState({
              itemsNotes: result
            });
          }
          // если сертификаты
          if (req.menuKind == 5) {
            this.setState({
              itemsCerts: result
            });
          }
          // если ключи
          if (req.menuKind == 6) {
            this.setState({
              itemsKeys: result
            });
          }
        },
        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
        // чтобы не перехватывать исключения из ошибок в самих компонентах.
        (error) => {
          this.ShowSnack(true, 'Ошибка ' + error);
          this.setState({
            isLoaded: true,
            error
          });
        }
      )
      .finally(() => {
        // this.setState({ networkActivity: false });
        this.ShowLoader(false);
      });
  }




  render() {


    return (
      <div  >
        <Header accid={this.state.accid} />
        <Login accid={this.state.accid} backurl={this.state.backurl} login={this.Login} logout={this.LogOut} ShowSnack={this.ShowSnack} ShowLoader={this.ShowLoader}
        />
        <MenuTopWrap menuid={this.state.menuid} accid={this.state.accid} backurl={this.state.backurl} setmenu={this.SetMenu} itemsMails={this.state.itemsMails}
          itemsSites={this.state.itemsSites} itemsCredits={this.state.itemsCredits} itemsNotes={this.state.itemsNotes} itemsDocuments={this.state.itemsDocuments} loaditems={this.LoadItems} deleteitem={this.DeleteItem} ShowAdd={this.ShowAdd} setSelectedItem={this.SetSelectedItem}
          itemsCerts={this.state.itemsCerts} itemsKeys={this.state.itemsKeys}
          selected={this.state.selected} ClearSelected={this.ClearSelected} LoadItem={this.LoadItem} ShowSnack={this.ShowSnack} ShowLoader={this.ShowLoader} headers={this.state.headers}
          filter={this.state.filter} SetFilter={this.SetFilter} SetShowdeleted={this.SetShowdeleted} showdeleted={this.state.showdeleted}
        />
        <Body accid={this.state.accid} menuid={this.state.menuid} />
        <AddEmail backurl={this.state.backurl} accid={this.state.accid} showAddMail={this.state.showAddMail}
          ShowAdd={this.ShowAdd} LoadItems={this.LoadItems} ShowSnack={this.ShowSnack}
          selectedItem={this.state.selected} kindEdit={this.state.kindEdit} ShowLoader={this.ShowLoader} headers={this.state.headers} AddItem={this.AddItem} DeleteItem={this.DeleteItem}
        ></AddEmail>
        <AddSite backurl={this.state.backurl} accid={this.state.accid} showAddSite={this.state.showAddSite}
          ShowAdd={this.ShowAdd} LoadItems={this.LoadItems}
          selectedItem={this.state.selected} kindEdit={this.state.kindEdit} ShowSnack={this.ShowSnack} ShowLoader={this.ShowLoader} headers={this.state.headers} AddItem={this.AddItem}
          DeleteItem={this.DeleteItem}
        ></AddSite>
        <AddCredit backurl={this.state.backurl} accid={this.state.accid} showAddCredit={this.state.showAddCredit}
          ShowAdd={this.ShowAdd} LoadItems={this.LoadItems}
          selectedItem={this.state.selected} kindEdit={this.state.kindEdit} ShowSnack={this.ShowSnack} ShowLoader={this.ShowLoader} headers={this.state.headers} AddItem={this.AddItem}
          DeleteItem={this.DeleteItem}
        ></AddCredit>
        <AddNote backurl={this.state.backurl} accid={this.state.accid} showAddNote={this.state.showAddNote}
          ShowAdd={this.ShowAdd} LoadItems={this.LoadItems}
          selectedItem={this.state.selected} kindEdit={this.state.kindEdit} ShowSnack={this.ShowSnack} ShowLoader={this.ShowLoader} headers={this.state.headers} AddItem={this.AddItem}
          DeleteItem={this.DeleteItem}
        ></AddNote>
        <AddDocument backurl={this.state.backurl} accid={this.state.accid} showAddDocument={this.state.showAddDocument}
          ShowAdd={this.ShowAdd} LoadItems={this.LoadItems}
          selectedItem={this.state.selected} kindEdit={this.state.kindEdit} file={this.state.file} ShowSnack={this.ShowSnack} ShowLoader={this.ShowLoader} headers={this.state.headers} AddItem={this.AddItem}
          DeleteItem={this.DeleteItem} SetFile={this.SetFile} SaveFile={this.SaveFile}
        ></AddDocument>
        <AddCert backurl={this.state.backurl} accid={this.state.accid} showAddCert={this.state.showAddCert}
          ShowAdd={this.ShowAdd} LoadItems={this.LoadItems}
          selectedItem={this.state.selected} kindEdit={this.state.kindEdit} ShowSnack={this.ShowSnack} ShowLoader={this.ShowLoader} headers={this.state.headers} AddItem={this.AddItem}
          DeleteItem={this.DeleteItem}
        ></AddCert>
        <AddKey backurl={this.state.backurl} accid={this.state.accid} showAddKey={this.state.showAddKey}
          ShowAdd={this.ShowAdd} LoadItems={this.LoadItems}
          selectedItem={this.state.selected} kindEdit={this.state.kindEdit} ShowSnack={this.ShowSnack} ShowLoader={this.ShowLoader} headers={this.state.headers} AddItem={this.AddItem}
          DeleteItem={this.DeleteItem}
        ></AddKey>
        <Snack showOpen={this.state.snackbar} msg={this.state.snackmsg} ShowSnack={this.ShowSnack} ></Snack>
        <Loader loading={this.state.loading} ShowLoader={this.ShowLoader}  ></Loader>
        <Footer />

      </div>
    );
  }

  componentDidMount() {
    // this.LoadItems();
  }
}

export default App;
