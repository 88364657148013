
import Button from "@material-ui/core/Button";
import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';


const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'sitename', headerName: 'Сайт', width: 210 },
    { field: 'login', headerName: 'Логин', width: 180 },
    { field: 'email1', headerName: 'Почта1', width: 210 },
    { field: 'phone1', headerName: 'Телефон1', width: 130 },
    { field: 'info', headerName: 'Коммент', width: 210 },
    { field: 'createdStr', headerName: 'Создан', width: 130 },
    { field: 'updatedStr', headerName: 'Обновлен', width: 130 },

];


const Sites = (props) => {
    const [selectionModel, setSelectionModel] = React.useState([]);

    const handleSelected = (selected) => {
        // props.setselitem(selected[0]);
        // LoadItem(selected)
        var obj = new Object();
        obj.id = selected;
        props.setSelectedItem(obj);
    };


    return (
        <div className='div-flex'>
            <div style={{ 'maxWidth': 1300, width: '100%' }}>
                <DataGrid density pageSize={25} headerHeight={25} rowHeight={25} hideFooterPagination={false}
                    rows={props.itemsSites} columns={columns} checkboxSelection={false} autoHeight={true}
                    onSelectionModelChange={(newSelection) => {
                        // let x = newSelection.selectionModel[0];
                        // console.log(x)
                        setSelectionModel(newSelection.selectionModel);
                        handleSelected(newSelection.selectionModel)

                    }}
                    selectionModel={selectionModel}
                />
            </div>
        </div>
    );
}

export default Sites;