import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ConfirmDialog from '../Helpers/ConfirmDialog';
import FileUpload from '../Helpers/FileUpload';

import './../App.css';
import { Info, PinDropSharp } from '@material-ui/icons';

const AddDocument = (props) => {

    const [docid, setDocid] = React.useState('');
    const [docname, setDocname] = React.useState('');
    const [info, setInfo] = React.useState('');
    const [filename, setFilename] = React.useState('');

    const [confirmOpen, setConfirmOpen] = React.useState(false);

    const hanSetDocname = (event) => {
        setDocname(event.target.value);
    };
    const hanSetInfo = (event) => {
        setInfo(event.target.value);
    };
    const hanSetFilename = (event) => {
        setFilename(event.target.value);
    };



    const handleClose = () => {
        setDocid('');
        setDocname('');
        setInfo('');
        setFilename('');
        props.SetFile();
        props.ShowAdd(false);
        // props.SetFileName('');
    };

    const handleEntered = () => {
        if (props.kindEdit == 2) {
            setDocid(props.selectedItem.id);
            setDocname(props.selectedItem.name);
            setInfo(props.selectedItem.info);
            setFilename(props.selectedItem.fileName);
        }
    };

    const handleCreateClick = () => {
        if (props.kindEdit == 2) {
            setConfirmOpen(true);
        } else {
            handleCreate();
        }
    }

    const SetFileName = (file) => {

        setFilename(file.name);
        // alert(props.file.name);
    }

    const handleLoadFile = () => {
        // alert(filename);
        if (!filename) {
            alert("Файла не существует");
            return;
        }
        fetch(props.backurl + "Download/" + docid,
            {
                method: 'GET',
                headers: props.headers,
                // body: JSON.stringify(docid)
            }
        )
            .then(response => response.blob())
            .then(blob => {
                var url = window.URL.createObjectURL(blob);
                var a = window.document.createElement('a');
                a.href = url;
                a.download = filename;
                window.document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again         
            });
    }

    // const download = (dataurl, filename) => {
    //     var a = document.createElement("a");
    //     a.href = dataurl;
    //     a.setAttribute("download", filename);
    //     a.click();
    //     return false;
    // }

    const handleCreate = () => {
        if (!docname || docname == '' || docname.length == 0) {
            props.ShowSnack(true, "Укажите имя Документа");
            return;
        }
        props.ShowLoader(true);
        var req = new FormData();
        req.accountId = props.accid;
        req.name = docname;
        req.info = info;
        // req.documentstr = document;
        // alert(props.file.name);
        // req.append("fileName", props.file.name);
        // req.fileName = props.file.name;
        // req.formFile = props.file.stream;
        // req.append("fileName", props.file.name);
        // var file = new Object();
        // file.arrayBuffer = props.file.arrayBuffer;
        // file.lastModified = props.file.lastModified;



        // req.file = file;
        //req.documentstr = document;
        // если изменение
        if (props.kindEdit == 2) {
            req.id = docid;
        }

        fetch(props.backurl + "adddocument",
            {
                method: 'POST',
                headers: props.headers,
                body: JSON.stringify(req)
            }
        )
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log(result);
                    // var email = new Object();
                    // email.id = result.id;
                    // email.accountId = req.accountId;
                    // email.name = req.email;
                    // email.password = req.email;
                    // props.AddEmailFunc(email);
                    if (props.kindEdit == 2) {
                        // props.LoadItems(0);
                        props.DeleteItem(result.obj.id);
                        props.AddItem(result.obj);
                    } else {
                        // если добавление
                        props.AddItem(result.obj);
                    }
                    props.SaveFile(result.obj.id);

                    props.ShowAdd(false);

                    handleClose();
                },
                (error) => {
                    props.ShowSnack(true, "Ошибка - " + error)
                }
            ).finally(() => {
                // this.setState({ networkActivity: false });
                props.ShowLoader(false);
            });

    };

    return (

        <div >
            <Dialog open={props.showAddDocument} onEntered={handleEntered} onClose={handleClose} maxWidth='sm'>
                {/* <Dialog open={props.showAddDocument} onClose={handleClose} maxWidth='sm'> */}
                <DialogTitle >Документы</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div className='div-flex' >
                            <TextField
                                margin="dense"
                                id="name"
                                label="Имя*" value={docname}
                                type="text" onChange={hanSetDocname}
                            /> <TextField
                                margin="dense"
                                id="name"
                                label="Комментарий" fullWidth value={info}
                                type="text" onChange={hanSetInfo}
                            />
                            <FileUpload SetFile={props.SetFile} SaveFile={props.SaveFile} SetFileName={SetFileName}></FileUpload>
                        </div>
                        <div className='div-flex'>
                            <TextField
                                margin="dense"
                                id="name" InputProps={{
                                    readOnly: true,
                                }}
                                label="Имя файла"
                                type="text" value={filename} onChange={hanSetFilename}

                            />
                            <Button onClick={handleLoadFile} size="small" color="primary">СКАЧАТЬ</Button>
                        </div>
                    </DialogContentText>


                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleCreateClick} color="primary">
                        Создать/Изменить
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmDialog
                title="ИЗМЕНЕНИЕ"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={handleCreate}
            >
                Изменить все данные документа?
            </ConfirmDialog>
        </div>
    );
}

export default AddDocument;