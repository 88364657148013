import MenuTop from './MenuTop';
import Button from "@material-ui/core/Button";



const MenuTopWrap = (props) => {


    if (props.accid == 0) {
        return null;
    }

    return (
        <div >
            <MenuTop backurl={props.backurl} setmenu={props.setmenu} itemsMails={props.itemsMails} itemsSites={props.itemsSites} itemsCredits={props.itemsCredits} itemsNotes={props.itemsNotes} itemsDocuments={props.itemsDocuments} loaditems={props.loaditems} deleteitem={props.deleteitem} menuid={props.menuid} setSelectedItem={props.setSelectedItem} itemsCerts={props.itemsCerts} itemsKeys={props.itemsKeys}
                selected={props.selected} ShowAdd={props.ShowAdd} ClearSelected={props.ClearSelected} LoadItem={props.LoadItem} ShowSnack={props.ShowSnack}
                ShowLoader={props.ShowLoader} headers={props.headers} filter={props.filter} SetFilter={props.SetFilter} SetShowdeleted={props.SetShowdeleted} showdeleted={props.showdeleted}

            />
            {/* загружает список в зависимости от выбранного меню */}

            <br />
            {/* {props.items}
            {props.items}
            <div>
                <ul>
                    {props.items.map(item => (
                        <li key={item}>{item}</li>
                    ))}
                </ul>
            </div> */}
        </div>
    );


}



export default MenuTopWrap;