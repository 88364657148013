import './../Styles/Header.css'
import { Settings } from './../Helpers/Settings.js'

const Header = (props) => {
    if (props.accid != 0)
        return null;

    return (
        <div className="Header">
            {Settings.AppName}
        </div>
    );
}

export default Header;