
import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { PinDropSharp } from "@material-ui/icons";
import { XGrid } from '@material-ui/x-grid';

const columns = [
    { field: 'id', headerName: 'Id', width: 70 },
    { field: 'email1', headerName: 'Почта', width: 220 },
    { field: 'phone1', headerName: 'Телефон1', width: 130 },
    { field: 'nickname', headerName: 'Никнейм', width: 180 },
    { field: 'info', headerName: 'Комментарий', width: 300 },
    { field: 'createdStr', headerName: 'Создана', width: 130 },
    { field: 'updatedStr', headerName: 'Обновлена', width: 130 },
];

const Emails = (props) => {

    const handleSelected = (selected) => {
        // props.setSelectedItem(selected);
        // LoadItem(selected)

        var obj = new Object();
        obj.id = selected;
        props.setSelectedItem(obj);
    };

    const [selectionModel, setSelectionModel] = React.useState([]);
    return (
        <div>
            <div className='div-flex'>
                <div style={{ 'maxWidth': 1200, width: '100%' }}>
                    <DataGrid autoHeight hideFooterSelectedRowCount hideFooterPagination={false}
                        rows={props.itemsMails}
                        columns={columns}
                        pageSize={15} headerHeight={30} rowHeight={30}
                        checkboxSelection={false}
                        density
                        onSelectionModelChange={(newSelection) => {
                            //let x = newSelection.selectionModel[0];
                            //console.log(x)
                            setSelectionModel(newSelection.selectionModel);
                            handleSelected(newSelection.selectionModel)
                            // setSelectionModel(newSelection);
                            // handleSelected(newSelection)

                        }}
                        selectionModel={selectionModel}
                    />

                </div>

            </div>


        </div>
    );
}

export default Emails;