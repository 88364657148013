import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';



const Loader = (props) => {
    // const { onClose, value: valueProp, open, ...other } = props;
    // const [value, setValue] = React.useState(valueProp);
    // const radioGroupRef = React.useRef(null);

    // React.useEffect(() => {
    //     if (!open) {
    //         setValue(valueProp);
    //     }
    // }, [valueProp, open]);

    // const handleEntering = () => {
    //     if (radioGroupRef.current != null) {
    //         radioGroupRef.current.focus();
    //     }
    // };

    const handleCancel = () => {
        props.ShowLoader(false);
    };

    const handleOk = () => {
        props.ShowLoader(false);
    };



    return (
        <div>
            <Dialog

                disableEscapeKeyDown

                // onEntering={handleEntering}
                aria-labelledby="confirmation-dialog-title"
                open={props.loading}
            // {...other}
            >
                <DialogTitle id="confirmation-dialog-title">ЗАГРУЗКА</DialogTitle>
                <DialogContent dividers>
                    <LinearProgress />
                </DialogContent>
                {/* <DialogActions>
                    <Button autoFocus onClick={handleCancel} color="primary">
                        Cancel
        </Button>
                    <Button onClick={handleOk} color="primary">
                        Ok
        </Button>
                </DialogActions> */}
            </Dialog>
        </div>
    );
}

export default Loader;